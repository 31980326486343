import React from 'react'
import styles from '../styles/Text.module.css'
const Text = () => {
  return (
    <div className={styles.txtWrapper}>
        What Kind of Adventure Are You <em>Craving</em>?
    </div>
  )
}

export default Text