import React from "react";
import styles from "../../styles/Text.module.css";

const Text4 = () => {
  return (
    <div className={styles.Text4Wrapper}>
      <div>The best Adventurers have to say</div>

    </div>
  );
};

export default Text4;
