import React from "react";
import styles from "../../styles/Text.module.css"

const Text6 = () => {
  return (
    <div className={styles.Txt6Wrapper}>
      <p>
        Best locations that <em>your student should visit</em>
      </p>
    </div>
  );
};

export default Text6;
