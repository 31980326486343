import React from "react";
import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import Footer from "./components/Footer/Footer";

const Home = () => {
    return (
      <div>
        <Header />
        <Content />
        <Footer />
      </div>
    );
  };

export default Home


