import React from "react";
import styles from "../../../styles/Text.module.css";
const Para3 = () => {
  return (
    <ul className={styles.Para3}>
      <li>Ready to take your corporate</li>
      <li>team,college buddies, or school</li>
      <li>group on an epic adventure?</li>
    </ul>
  );
};

export default Para3;
